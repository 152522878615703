@import "@/assets/styles/styles.scss";




























































































































































.orderPage {
    color: #656565;
    font-size: 16px;
    justify-content: center;
}

.orderPageDivider {
    background-color: #07070721;
    height: 1px;
}

.orderPageCard {
    background-color: $white;
    margin: 16px;
    // padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}
