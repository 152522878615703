@import "@/assets/styles/styles.scss";




























































.currency {
    cursor: pointer;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding: 16px 16px 8px 20px;
    margin: 4px 0;
}

.currency:hover {
    background-color: $cur--hover;
}

.currency.selected {
    cursor: auto;
    background-color: $cur--select;
}

.currency.selected:after {
    height: 100%;
    width: 2.4px;
    display: block;
    content: " ";
    bottom: 0;
    left: 0;
    position: absolute;
    background-color: $orange;
}

.currency:last-child {
    margin: 8px 0 16px 0;
}

.currency .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.currency .name {
    color: $cur-name;
}

.currency .reserve {
    color: $cur-name;
    font-size: 14px;
    white-space: nowrap;
}

.currency .reserve .code {
    color: $code;
}
