@import "@/assets/styles/styles.scss";






































































.technical-break {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% - 32px);
    // position: absolute;
    background-color: $white;
    border-radius: 6px;
    padding: 8% 16px 16px 16px;
    margin: 16px;
    opacity: 0.88;
    z-index: 994;
}

.technical-break > .image {
    width: 100%;
    max-width: 240px;
    margin-bottom: 48px;
}

.technical-break > .title {
    text-align: center;
    color: #323232;
    font-size: 32px;
    margin-bottom: 24px;
}

.technical-break > .date {
    text-align: center;
    color: #323232;
    font-size: 20px;
    margin-bottom: 16px;
}

.technical-break > .sub-title {
    text-align: center;
    max-width: 640px;
    color: #323232;
    font-size: 18px;
    line-height: 24px;
}
