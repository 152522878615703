@import "@/assets/styles/styles.scss";























































































































.rejectedCardSection_description {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 16px;
}

.rejectedCardSection_descriptionImport {
    font-size: 14px;
    line-height: 20px;
    background-color: $white;
    padding: 0px 16px;
    margin-top: 5px;
    font-weight: 600;
}

.rejectedCardSection_description > p {
    margin-bottom: 16px;
}

.rejectedCardSection_errorComment {
    color: red;
    text-align: center;
    margin-top: 10px;
}

.rejectedCardSection_errorCommentText {
    text-align: center;
    margin-top: 10px;
    font-weight: 600;
}

.rejectedCardSection_btnsWrap {
    display: flex !important;
    justify-content: center;
}

.rejectedCardSection_btnsWrap button:first-child {
    margin-right: 10px;
    display: inline-block;
}
.rejectedCardSection_btnsWrap button:last-child {
    margin-left: 10px;
}
