@import "@/assets/styles/styles.scss";











































.button {
    cursor: pointer;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    text-align: center;
    background-color: $orange;
    padding: 10px 16px;
    margin: 0;
    border-radius: $border-sm;
    box-shadow: 2px 2px 6px 0 rgba(53, 151, 250, 0.24);
    text-decoration: none;
    border: none;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
}
.button.full-width {
    width: 100%;
}
.button-outline {
    background-color: inherit;
    border: 1px solid #acacac;
    box-shadow: none;
}

.button-outline:hover {
    color: #fff;
    box-shadow: none;
    background: $cur--hover;
}

.button:focus {
    outline: none;
}

.button-value {
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    font-size: 16px;
    color: $white;
}

.button-outline .button-value {
    color: $grafit;
}

.spinner-block {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.button-value-hidden {
    color: transparent !important;
}
.button.button-disabled {
    background-color: $orange-disabled;
}
.button.button-outline.button-disabled {
    background-color: inherit;
}
