@import "@/assets/styles/styles.scss";





















































.partner {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.partner-close {
    align-self: flex-end;
    cursor: pointer;
}

.partner-icon {
    width: 100px;
    margin: 20px auto;
}

.partner-header {
    font-size: 24px;
    margin-bottom: 32px;
    color: #161616;
}

.partner-description {
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 32px;
}

.partner-download_coursers {
    text-decoration: underline;
    margin-bottom: 32px;
    font-size: 16px;
    cursor: pointer;
    color: $link;
    transition: all 0.32s;

    &:hover {
        color: #2a5885;
    }
}

.partner-signup {
    padding: 15px 0;
}

.wrapper {
    padding: 0rem 1rem 1rem 1rem;
    background-color: #fff;
    color: #4d4d4d;
}
