@import "@/assets/styles/styles.scss";






























.footer-wrap {
    width: 100%;
    height: 48px;
}

.footer-content {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    max-width: 1200px;
    padding: 0 16px;
    margin: 0 auto;
    overflow: hidden;
}

.footer-content .best-logo {
    margin-right: 16px;
}

.footer-content .copyright {
    color: $grafit;
    font-size: 14px;
    line-height: 20px;
}

.footer-content .social {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.footer-content .social > .icon {
    cursor: pointer;
    color: $grafit;
}

.footer-content .social > .icon:not(:last-child) {
    margin-right: 16px;
}

@media (max-width: 749px) {
    .footer-wrap {
        height: 120px;
    }

    .footer-content {
        flex-direction: column;
        padding: 8px;
    }

    .footer-content .best-logo {
        margin: 8px 0 16px 0;
    }

    .footer-content .copyright {
        text-align: center;
        margin-bottom: 16px;
    }
}
