@import "@/assets/styles/styles.scss";




























.regulations {
    color: #656565;
    font-size: 16px;
}

.regulations .card {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 16px 24px;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.regulations .card .page-name {
    color: $grey-text;
    font-size: 28px;
    margin-bottom: 24px;
}

.regulations .card .content {
    line-height: 24px;
    color: #373737c7;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    justify-content: center;
    max-width: 1100px;
}

@media (max-width: 739px) {
    .regulations .card {
        margin: 8px;
        padding: 24px 16px;
    }
}
