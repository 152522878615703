@import "@/assets/styles/styles.scss";




















































































































































































































































.cashback-charts .info-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    flex-wrap: wrap;
}

.cashback-charts .info-block_description {
    background-color: #ebeff6;
    padding: 10px 16px !important;
    border-radius: $border-sm;
    text-align: start !important;
    line-height: 25px;
    display: block !important;
    flex-grow: 1;
    margin: 0px 20px;
    color: #565656;
    width: min-content;
}

.cashback-charts .info-block_description h4 {
    font-size: 16px;
    margin-bottom: 10px;
}

.cashback-charts .info-block_description h4 b {
    font-weight: 600;
}

.cashback-charts .info-block_description ul {
    font-size: 14px;
}

.cashback-charts .info-block_description li,
.cashback-charts .info-block_description p {
    margin: 2px 0px;
    font-size: 14px;
}

.cashback-charts .info-block_description li b,
.cashback-charts .info-block_description p b {
    font-weight: 600;
    font-size: 14px;
}
