@import "@/assets/styles/styles.scss";



























.regulations {
    color: #656565;
    font-size: 16px;
}

.regulations .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.regulations .card .page-name {
    color: $grey-text;
    font-size: 28px;
    margin-bottom: 24px;
}

.regulations .card .content {
    line-height: 24px;
    color: $grafit;
}

@media (max-width: 739px) {
    .regulations .card {
        margin: 8px;
        padding: 24px 16px;
    }
}
