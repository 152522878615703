@import "@/assets/styles/styles.scss";




































































































































































































































































.reward-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    background-color: $white;
    transition: width 0.32s;
    z-index: 999;
    box-shadow: -2px 0 2px -2px rgba(0, 0, 0, 0.16);
    overflow-x: hidden;
    overflow-y: auto;
}

.reward-form {
    padding: 24px 32px;
}

.header-name {
    color: $grafit;
    font-size: 28px;
    margin-bottom: 32px;
}

.icon-close {
    cursor: pointer;
    position: absolute;
    top: 20px;
    left: 24px;
    color: $black;
}

.message {
    font-size: 18px;
    line-height: 26px;
    color: $white;
    font-weight: 500;
    margin: 64px 0;
}

.button {
    margin: 8px 0 0 0 !important;
}

.reward-error {
    color: red;
    font-weight: 400;
}

@media (max-width: 479px) {
    .reward-wrapper {
        min-width: 0;
    }
}
