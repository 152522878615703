@import "@/assets/styles/styles.scss";
























































































































.noVerifyExchangeSection {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ff0000d9;
    margin: 10px 0px;
}

.noVerifyExchangeSection_offerText {
    // color: blue;
    margin-top: 10px;
}

.noVerifyExchangeSection_btn {
    color: #3597fa;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 5px;
}
.noVerifyExchangeSection_btn:hover {
    text-decoration: underline;
}
