@import "@/assets/styles/styles.scss";






























































.svg-icon-copy {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.copy-icon > path {
    fill: #565656;
}
.copy-icon:hover > path {
    fill: #3597fa;
}
.copy-text {
    color: #3597fa;
    font-size: 14px;
}
