@import "@/assets/styles/styles.scss";














































































































































.upload-receipt_image-wrap-layout {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.upload-receipt_image-wrap {
    width: 50%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

@media (max-width: 600px) {
    .upload-receipt_image-wrap {
        width: 100%;
    }
}

.upload-receipt_image-content {
    width: 100%;
    border: 2px solid $grafit;
    border-radius: 10px;
    overflow: hidden;
}
.upload-receipt_image-content.uploading {
    opacity: 0.2;
}

.upload-receipt_image-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.upload-receipt_uploading-icon {
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
}

.upload-receipt_upload-btn-wrap {
    margin: 16px 0;
    display: flex;
    justify-content: center;
}

.upload-receipt_upload-btn-wrap #add-image {
    display: none;
}

.upload-receipt_upload-btn-wrap #add-image + label {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: $grafit;
    padding: 10px 16px 14px 16px;
    border: 1px solid $orange;
    font-size: 14px;
    border-radius: 8px;
    width: max-content;
    min-width: 50%;
    text-align: center;
}

.upload-receipt_upload-btn-wrap #add-image:focus + label,
#add-image + label:hover {
    color: $orange;
}

.upload-receipt_filename-wrap {
    min-height: 30px;
}
.upload-receipt_filename-text {
    text-align: center;
    margin-top: 20px;
}
