@import "@/assets/styles/styles.scss";














































































































.order {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-sm;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
}

.info {
    padding: 16px 12px;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}

.status-text-with-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.status-text-with-number .status-text {
    color: $white;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.status-text-with-number .number {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
}

.info .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grey-lg-text;
    font-size: 14px;
}

.info .date svg {
    margin-right: 8px;
}

.currency {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0 12px;
}

.currency.take {
    padding-top: 16px;
}

.currency.give {
    padding-bottom: 16px;
}

.currency .image {
    -o-object-fit: cover;
    object-fit: cover;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    margin-right: 12px;
}

.currency .details {
    padding-top: 4px;
    width: 100%;
}

.currency .details .amount {
    color: $cur-name;
    font-size: 18px;
    margin-bottom: 8px;
}

.currency .details .name {
    color: $cur-name;
    font-size: 14px;
}

.icon-exchange {
    color: $cur-name;
    padding: 0 12px 0 14px;
    margin-bottom: 8px;
}

.status-text.verification {
    background-color: $yellow;
}

.status-text.payment-user {
    background-color: $yellow;
}

.status-text.check-payment {
    background-color: $yellow;
}

.status-text.payment-service {
    background-color: $yellow;
}

.status-text.rejected {
    background-color: $red;
}

.status-text.resolved {
    background-color: $green;
}

.status-text.checked {
    background-color: $green;
}

.status-text.error {
    background-color: $red;
}
