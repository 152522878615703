@import "@/assets/styles/styles.scss";






























































































.contacts {
    color: $black;
    font-size: 16px;
}

.contacts .card {
    background-color: $white;
    margin: 16px;
    padding: 24px 32px;
    border-radius: $border-sm;
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.32);
}

.contacts .card .page-name {
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 32px;
    display: flex;
    color: $grafit;
    text-align: center;
}

.options {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;
}

.options .option {
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    border-radius: 10px;
    width: -webkit-fill-available;
    margin: 8px 10px;
}

//.options .option:not(:last-child) {
//    margin-right: 64px;
//}

.options .option .icon {
    color: #565656;
    margin-right: 16px;
    display: flex;
    align-items: center;
}

.options .option .info > .title {
    color: $grafit;
    font-size: 18px;
    margin-bottom: 8px;
}

.options .option .info > .sub-title {
    font-size: 16px;
    color: $grey-lg-text;
}

.description {
    color: $grafit;
    line-height: 26px;
}

.border {
    height: 2px;
    width: 100%;
    margin: 15px 0;
}

.links-wrap > p:not(:last-child) {
    margin-bottom: 16px;
}

.links-wrap > p:nth-child(9) {
    margin-bottom: 32px;
}

.links-wrap > p:last-child {
    line-height: 26px;
}

.links-wrap > p > a {
    color: #0081ff;
    text-decoration: underline;
}

.links-wrap > p > a:hover {
    color: #2a5885;
    text-decoration: underline;
}

.monitoring-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-shrink: 0;
    align-items: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 16px;
}

.monitoring-wrap > a {
    width: 88px;
    height: 32px;
}

.monitoring-wrap > a:not(:last-child) {
    margin-right: 16px;
}

.monitoring-wrap > a > img {
    width: 100%;
    height: 100%;
}

@media (max-width: 739px) {
    .contacts .card {
        margin: 8px;
        padding: 24px 16px;
    }
    .options {
        flex-direction: column;
    }

    .border {
        margin: 10px 0;
    }
}

.open-link {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    color: $link;
    transition: all 0.32s;

    &:hover {
        color: #2a5885;
    }
}

.chat-img {
    width: 28px;
}
