@import "@/assets/styles/styles.scss";

















































































.card {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: $border-sm;
    padding: 16px;
    box-sizing: border-box;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
}

.info {
    padding: 6px 6px;
}

.status-text-with-number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.status-text-with-fullName {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    font-weight: 400;
    color: $grafit;
}

.status-text-with-number .status-text {
    color: #fff;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: $border-sm;
}

.status-text-with-number .number {
    color: $grafit;
    font-size: 20px;
    font-weight: 500;
}

.info .date {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $grafit;
    font-size: 14px;
}

.info .date svg {
    color: $grafit;
    margin-right: 8px;
}

.status-text.verification {
    background-color: $yellow;
}

.status-text.rejected {
    background-color: $red;
}

.status-text.resolved {
    background-color: $green;
}

.status-text.banned {
    background-color: $red;
}
