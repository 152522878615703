@import "@/assets/styles/styles.scss";










































































































.select {
    position: relative;
    font-family: "Gotham Pro", -apple-system, system-ui, BlinkMacSystemFont, “Segoe UI”, Roboto, “Helvetica Neue”, Arial, sans-serif;
    margin: 16px 0;
    outline: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    background-color: #fff;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat, repeat;
    background-position: right 1.7em top 50%, 0 0;
    background-size: 0.65em auto, 100%;
}

.selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: $grafit;
    margin: 16px;
    padding: 12px 0;
    border-bottom: 1.2px solid rgba(0, 0, 0, 0.12);
}

.selected-reserve {
    display: flex;
    width: 100%;
    padding: 0 2rem 0 0;
    align-items: center;
}

.selected .image {
    margin-left: 1rem;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}

.selected-name {
    display: flex;
    align-items: center;
}

.options {
    width: 100%;
    position: absolute;
    background-color: $white;
    padding: 4px 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.32);
    z-index: 2;
}

.option {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: $grafit;
    padding: 8px 16px;
    margin: 8px 0;
    background-color: white;
}

.option:hover {
    background-color: #ff88002b;
}

.selectedOptions {
    background-color: #ff88002b;
}

.option .image {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    margin-right: 16px;
    -o-object-fit: cover;
    object-fit: cover;
}
