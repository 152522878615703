@import "@/assets/styles/styles.scss";

































.lang-switcher {
    margin-right: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: opacity 0.4s;
    padding: 0 3px;
    border-radius: 2px;
    &:hover {
        opacity: 0.7; }

    .lang-switcher_triange {
        margin-left: 5px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #c1c1c1; }
    .name {
        margin-right: 7px;
        color: #565656; } }
