@import "@/assets/styles/styles.scss";















































































































































































.dialog-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    pointer-events: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9996;
}

.dialog-container.active {
    pointer-events: auto;
}

.dialog-container.active .dialog {
    opacity: 1 !important;
    transform: scale(1) !important;
    max-width: 700px;
    transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-property: opacity, transform;
}

.dialog-backdrop {
    position: fixed;
    z-index: 2;
}

.dialog {
    min-width: 280px;
    max-width: 700px;
    max-height: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column;
    flex-flow: column;
    overflow: auto;
    position: relative;
    z-index: 3;
    outline: none;
    opacity: 0;
    transform: scale(0.9, 0.85);
    transform-origin: center center;
    transition: opacity 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1) 0.05s;
    will-change: opacity, transform;
}

.dialog.reference {
    transform-origin: top center;
}

.dialog.transition-off {
    transition: none !important;
}

.dialog p {
    margin: 0;
}

.dialog-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
    padding: 24px 24px 0;
}

.dialog-content {
    padding: 0 24px 24px;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    overflow: auto;
    position: relative;
    background: linear-gradient(180deg, $white, $white 1px, transparent 0), linear-gradient(0deg, #fff, #fff 3px, transparent 0), linear-gradient(180deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12) 1px, transparent 0), linear-gradient(0deg, rgba(0, 0, 0, 0.2) 1px, rgba(0, 0, 0, 0.2) 2px, transparent 0);
    background-attachment: local, local, scroll, scroll;
}

.dialog-content:first-child {
    padding-top: 24px;
}

.dialog-content p:first-child:not(:only-child) {
    margin-top: 0;
}

.dialog-content p:last-child:not(:only-child) {
    margin-bottom: 0;
}

.dialog-body {
    margin: 0 -24px;
    padding: 0 24px;
    overflow: auto;
}

.dialog-actions {
    min-height: 52px;
    padding: 8px 24px 16px 24px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    position: relative;
}

.dialog-actions:before {
    height: 1px;
    position: absolute;
    top: -1px;
    right: 0;
    left: 0;
    background-color: $white;
    content: " ";
}

.dialog-actions .md-button {
    min-width: 64px;
    margin: 0;
    padding: 0 8px;
}

.dialog-actions .md-button + .md-button {
    margin-left: 8px;
}

.dialog-container .dialog {
    background-color: $white;
    padding: 16px;
    border-radius: $border-sm;
}

.dialog-container .dialog.no-spacing {
    padding: 0;
}

@media (max-width: 479px) {
    .dialog-container .dialog {
        width: calc(100% - 16px);
        min-width: calc(100% - 16px);
        max-width: calc(100% - 16px);
        max-height: calc(100% - 16px);
        margin: 8px;
    }
}
