@import "@/assets/styles/styles.scss";



















.cashback-description p {
    color: $grafit;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 16px;
}
